<template>
  <!--
    The view for the ApiError-component
  -->
  <component
    :is="`Error${ errorId }`"
    :from-route="fromRoute"
  />
</template>

<script>
export default {
  name: "ApiError",
  components: {
    Error404: () => import('@/components/Base/Error404.vue'),
    Error503: () => import('@/components/Base/Error503.vue')
  },
  props: {
    errorId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      fromRoute: null
    }
  },
  metaInfo () {
    return {
      title: this.$t('error')
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.fromRoute = from;
    })
  },
}
</script>
